body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
  background-color: #f1f3fc5c;
  color: rgb(79, 79, 79);
}

p {
  font-family: sans-serif;
  font-size: 1.20rem;
}

a {
  color: #4090c2;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}


.hover:hover {
  cursor: pointer;
  
}

.MuiPaper-root {
  border-radius: 25px;
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.10) !important;
  padding: 0.5rem 0.85rem;
}

.MuiIconButton-root, .MuiButtonBase-root {
  background: linear-gradient(145deg, #f5f5f5, #faf8f8);
  box-shadow:  6px 6px 12px rgba(195, 195, 195, 0.5),
             -6px -6px 12px #fdfdfd;
  overflow: hidden;
  text-align: start;
}

.MuiIconButton-root:hover, .MuiButtonBase-root:hover {
  transition: box-shadow 0.125s ease-in;
  /* background: linear-gradient(145deg, #e5e5e5, #ededed); */
  background: linear-gradient(145deg, #f5f5f5, #faf8f8);
  box-shadow:  6px 6px 12px rgba(195, 195, 195, 0.9),
             -6px -6px 12px #fdfdfd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.award-container {
  display: none;
  justify-content: flex-end;
  width: 100%;
  margin: 1rem;
}
.award {
  width: 50px;
  height: auto;
  margin: 0 0.5rem;
}
.award:hover {
  transition: 0.2s ease-in;
  transform: scale(1.2);
}
